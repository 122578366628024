.footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: var(--blue);
}

.copyright {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.copyright p {
  margin: 0;
  margin-top: 0.5rem;
  color: var(--lgrey);
  font-family: var(--body1);
}

.payment-images {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.payment-images h4 {
  color: var(--lgrey);
  margin: 0;
  font-size: 1rem;
  font-family: var(--script);
  letter-spacing: 0.1rem;
}

.payment-images img {
  max-height: 30px;
  padding-top: 1rem;
}

.z-logo {
  padding-bottom: 1rem;
}

.ven-logo {
  max-width: 200px;
}

.footer-link {
  color: var(--accent);
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
  }

  .copyright {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
  }

  .payment-images {
    align-items: center;
  }
}
