.faq-background {
  background: var(--dgrey);
}

.faq-background h1 {
  font-family: var(--script);
  color: var(--lgrey);
  letter-spacing: 0.15rem;
  text-align: center;
  font-size: 3rem;
  margin: 0 auto 1.5rem;
  padding-top: 2rem;
}

.faq-outer-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 2px solid var(--blue);
  width: 600px;
  border-radius: 10px;
  background: url(../../../public/signature-contract.jpg);
  background-color: var(--lgrey);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 0 10px #000;
  padding: 2rem;
  margin: 0.5rem;
}

.faq-item {
  max-width: 100%;
  margin: 1rem 0;
}

.faq-question {
  font-size: 2rem;
  font-family: var(--script);
  color: var(--blue);
  font-weight: bold;
  transition: all ease 0.3s;
  border: none;
  background: inherit;
}

.faq-question:hover {
  cursor: pointer;
  color: var(--accent);
}

.faq-answer {
  font-family: var(--body1);
  font-size: 1.25rem;
  color: var(--dgrey);
  line-height: 1.75;
}

.disclaimer {
  font-family: var(--body2);
  color: var(--lgrey);
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  padding: 2rem 1rem;
  letter-spacing: 0.03rem;
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .faq-container {
    max-width: 90%;
    padding: 1rem;
    background-position: center;
  }

  .faq-background h1 {
    font-size: 2.5rem;
  }

  .faq-question {
    font-size: 1.25rem;
  }

  .disclaimer {
    line-height: 1.6;
  }
}
