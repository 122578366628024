.about-container {
  margin-bottom: 3rem;
}

.about-text-container {
  flex-direction: column;
}

.seal {
  max-width: 100%;
  margin-left: 2rem;
  transition: ease all 0.3s;
  border-radius: 15px;
}

.pic-row {
  width: 20%;
  transition: ease all 0.3s;
  border-radius: 15px;
}

.harley:hover,
.seal:hover {
  transform: scale(1.03);
  box-shadow: 0 0 10px #000;
}

.about-container div {
  display: flex;
}

.about-center {
  align-items: center;
  justify-content: center;
}

.about-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 2rem;
}

.about-container h2 {
  font-size: 3rem;
  font-family: var(--script);
  color: var(--blue);
  letter-spacing: 0.1rem;
  text-align: center;
  margin: 3rem 0 1rem;
  padding: 0 0.5rem;
}

.about-container p {
  font-size: 1.5rem;
  font-family: var(--body1);
  padding: 2rem 4rem;
  line-height: 1.75;
  color: var(--dgrey);
  margin-top: 0;
}

.about-text-container p:last-child {
  text-align: center;
  padding-top: 0;
}

@media only screen and (max-width: 1068px) {
  .about-container div {
    flex-direction: column;
  }

  .about-around {
    flex-direction: row;
  }

  .about-container p {
    text-align: center;
  }

  .seal {
    max-width: 80%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .about-container h2 {
    font-size: 1.75rem;
  }

  .about-container p {
    padding: 2rem;
  }

  .about-around {
    flex-wrap: wrap;
    margin: 1rem;
  }

  .pic-row {
    width: 50%;
  }
}

@media only screen and (min-width: 1920px) {
  .about-container div {
    align-items: center;
    font-size: 2rem;
  }
}
