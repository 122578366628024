.fees-wrapper {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3rem;
}

.meeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.fees {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fees h3,
.fees h2,
.meeting h2 {
  color: var(--blue);
  font-family: var(--script);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0;
  letter-spacing: 0.1rem;
}

.fees p,
.meeting p {
  color: var(--dgrey);
  font-family: var(--body1);
  font-size: 1.75rem;
  margin-top: 0;
  text-align: center;
  padding: 0 2rem;
}

.meeting p {
  line-height: 1.5;
}

.meeting img {
  max-width: 90%;
  border-radius: 20px;
  transition: all ease 0.3s;
}

.meeting img:hover {
  box-shadow: 0 0 15px #000;
  transform: scale(1.02);
}

.fee-container {
  display: flex;
  justify-content: space-around;
}

.fee-container div {
  width: 33%;
}

.surcharges {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fee-container div {
  margin: 1rem;
}

.fees h3 {
  font-size: 2rem;
  margin-top: 0;
  font-family: var(--body1);
  margin-bottom: 1rem;
}

.fees h2 {
  margin: 0 1rem;
}

.fees p {
  font-family: var(--body1);
}

.feeDis {
  font-size: 1.25rem !important;
}

.prices-link {
  color: var(--accent);
  font-size: 1.5rem;
  font-family: var(--body1);
  margin-bottom: 1rem;
  letter-spacing: 0.075rem;
  font-weight: bold;
}

@media only screen and (max-width: 1068px) {
  .fees-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .fee-container {
    flex-direction: column;
  }

  .fee-container div {
    width: revert;
  }

  .meeting {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .meeting p,
  .fees p {
    font-size: 1.25rem;
  }
}
