.header-container {
  background: linear-gradient(
    to right,
    var(--accent) -60%,
    var(--blue) 25%,
    var(--dgrey)
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  user-select: none;
  transition: all 0.3s ease;
  padding: 0 1rem;
  animation: headerFade 0.5s ease-in-out;
  z-index: 10000;
}

.shadow {
  box-shadow: 0 0 10px #000;
  border-radius: 0 0 50px 50px;
}

.logo {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 1rem;
}

.logo img {
  height: 200px;
  margin: 1rem 0 0.5rem;
  transition: all 0.3s ease;
}

.bar-logo img {
  height: 100px;
}

.title {
  display: flex;
}

.title p,
.title h1 {
  color: var(--lgrey);
}

.title h1 {
  font-size: 3rem;
  font-family: var(--script);
  margin-bottom: 0;
  letter-spacing: 0.15rem;
  transition: all 0.3s ease;
}

.bar-title h1 {
  font-size: 2rem;
}

.title p {
  font-size: 1.5rem;
  margin: 0;
  text-align: left;
  font-family: var(--body1);
  letter-spacing: 0.1rem;
  transition: all 0.3s ease;
}

.bar-title p {
  font-size: 1.1rem;
}

.nav-menu-container {
  display: flex;
}

.subtitle {
  height: 30px;
  margin-bottom: 1rem;
}

@keyframes headerFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1068px) {
  .header-container {
    position: initial;
  }

  .logo img {
    height: 150px;
  }

  .title h1 {
    font-size: 2rem;
  }

  .title p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    flex-direction: column;
  }

  .nav-menu ul {
    height: 90%;
  }

  .logo {
    margin-right: 0;
    justify-content: center;
  }
}
