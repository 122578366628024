.hero-container {
  background: var(--lgrey);
  background-blend-mode: screen;
  background-image: url(../../../public/business.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  max-height: 1100px;
  justify-content: center;
  padding-top: 37vh;
}

.hero-container h3 {
  margin: 1rem 1rem 0;
  color: var(--blue);
  font-size: 2.25rem;
  letter-spacing: 0.1rem;
  font-family: var(--body1);
  font-weight: normal;
}

.text-container {
  font-family: var(--body1);
  font-size: 2rem;
  color: var(--dgrey);
  padding: 3%;
  text-align: right;
  line-height: 2;
  margin-top: 3rem;
}

.text-container button {
  margin: 1rem;
  min-height: 60px;
  min-width: 150px;
  background: var(--dgrey);
  color: var(--lgrey);
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  font-family: var(--script);
  font-weight: bold;
  letter-spacing: 0.1rem;
  transition: all ease 0.3s;
  box-shadow: 0 0 10px #000;
}

.text-container button:hover {
  cursor: pointer;
  background: var(--blue);
  box-shadow: none;
  transform: scale(0.97);
}

.contact {
  text-decoration: none;
  color: var(--blue);
  transition: all ease 0.3s;
  white-space: nowrap;
}

.contact:hover {
  color: var(--accent);
}

@media only screen and (min-width: 1068px) and (orientation: portrait) {
  .hero-container {
    padding-top: 15vh;
  }
}

@media only screen and (max-width: 1068px) {
  .hero-container {
    padding-top: 2rem;
  }

  .hero-container h3 {
    font-size: 2rem;
  }

  .text-container {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero-container {
    height: initial;
  }
  .hero-container h3 {
    font-size: 1.5rem;
  }

  .text-container {
    font-size: 1.25rem;
    margin-top: 0;
    line-height: 1.5;
  }

  .phone {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-width: 1920px) {
  .hero-container {
    padding-top: 25vh;
  }
}
