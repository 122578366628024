.nav-menu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-around;
  transition: all 0.3s ease;
  padding-inline-start: 0;
}

.nav-menu ul li {
  text-align: right;
  font-size: 1.5rem;
  font-family: var(--body1);
  transition: all ease 0.3s;
}

.nav-menu ul li a {
  text-decoration: none;
  color: var(--lgrey);
  transition: all ease 0.3s;
}
.nav-menu ul li a:hover {
  color: var(--accent);
  margin-right: 0.5rem;
}

.nav-menu-bar ul {
  flex-direction: row;
  gap: 2rem;
  padding-inline-start: 0;
  padding-right: 1rem;
  align-items: center;
  margin: 0;
  height: 100%;
}

.nav-menu-bar ul li:hover {
  margin-bottom: 0.75rem;
}

.nav-menu-bar ul li a:hover {
  margin-right: 0;
}

@media only screen and (max-width: 1068px) {
  .nav-menu ul li {
    font-size: 1.1rem;
  }

  .nav-menu ul li a:hover {
    margin-right: 0;
  }
}
