body {
  margin: 0;
  background: var(--lgrey);
}

html {
  font-size: 100%;
}

:root {
  --blue: #0f4057;
  --lgrey: #d4d2d5;
  --dgrey: #1f1f1f;
  --accent: #bd6fdb;
  --header: Slabo, serif;
  --body1: Dosis, sans-serif;
  --body2: Saraburn, sans-serif;
  --script: 'Bad Script', cursive;
}
