.form-container {
  background: var(--dgrey);
}

.form-container h3 {
  font-family: var(--body2);
  color: var(--lgrey);
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  padding: 2rem 1rem 0;
  letter-spacing: 0.03rem;
  font-weight: normal;
  text-transform: uppercase;
}

.form-container p {
  font-family: var(--body1);
  color: var(--lgrey);
  text-align: center;
  font-size: 1.25rem;
  font-style: italic;
}

.form-container h4 {
  font-family: var(--body1);
  color: var(--lgrey);
  text-align: center;
  font-size: 2rem;
  margin: 0;
  padding: 2rem 1rem 0;
  margin-top: 3rem;
  letter-spacing: 0.1rem;
}

.grey {
  background: var(--lgrey);
}

.form {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.form-row {
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.form-row input {
  height: 30px;
  width: 400px;
  color: var(--lgrey);
  font-family: var(--body1);
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  background: inherit;
  border: 1px solid var(--lgrey);
  padding: 0.5rem;
}

.form-row input::placeholder {
  color: var(--lgrey);
  font-family: var(--body1);
  font-size: 1.25rem;
  opacity: 0.7;
  font-weight: normal;
}

.bottom-label,
.form-row label {
  font-family: var(--body2);
  color: var(--lgrey);
  letter-spacing: 0.1rem;
  margin-bottom: 0.25rem;
}

.bottom-label {
  display: flex;
}

.disclaim {
  font-family: var(--body2);
  font-size: 2rem;
  text-align: center;
  color: var(--dgrey);
  width: 75%;
  margin: auto;
  padding-top: 1rem;
}

#description {
  height: 125px;
  width: 400px;
  color: var(--lgrey);
  font-family: var(--body1);
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  background: inherit;
  padding: 0.5rem;
  border: 1px solid var(--lgrey);
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type='datetime-local']::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}

#description::placeholder {
  color: var(--lgrey);
  font-family: var(--body1);
  font-size: 1.25rem;
  opacity: 0.7;
  font-weight: normal;
}

.submit-container {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.submit-container button {
  padding: 0.5rem;
  background: var(--lgrey);
  font-family: var(--body1);
  font-size: 1.25rem;
  color: var(--dgrey);
  border: none;
  margin-top: 0.5rem;
  border-radius: 5px;
  letter-spacing: 0.075rem;
  transition: ease all 0.3s;
  box-sizing: border-box;
}

.submit-container button:hover {
  background: var(--blue);
  color: var(--lgrey);
  cursor: pointer;
}

.success-container {
  display: flex;
  justify-content: center;
}

.success-message {
  height: 50px;
  width: 400px;
  background: var(--blue);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0.25rem;
}

.success-message p {
  font-family: var(--body1);
  color: var(--lgrey);
  font-size: 1.5rem;
  text-align: center;
}

@media only screen and (max-width: 1068px) {
  .form-row {
    width: initial;
    max-width: 500px;
  }

  .form-row input {
    width: initial;
    max-width: 400px;
    margin: 1rem;
  }

  .form-container h3 {
    padding: 1rem 1rem 0;
  }

  #description {
    margin: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }

  .form-row label {
    text-align: center;
  }

  .form-container h3 {
    line-height: 1.6;
  }

  .form-container h4 {
    width: initial;
  }

  #description {
    max-width: 300px;
  }

  .bottom-label {
    justify-content: center;
  }

  .success-message {
    width: 250px;
  }
}
